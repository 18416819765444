import React from 'react';

const ShowerTop = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="67.793"
    height="89.329"
    viewBox="0 0 67.793 89.329"
  >
    <g
      id="Picto_sous_la_douche"
      data-name="Picto sous la douche"
      transform="translate(-397.207 -1775.261)"
    >
      <path
        id="Tracé_946"
        data-name="Tracé 946"
        d="M175.814-790.992H163.851v-9.97h11.963Zm23.928,32.9a1.011,1.011,0,0,1-1,1H140.92a1.011,1.011,0,0,1-1-1v-4.984h59.819ZM162.684-787h14.3l19.939,19.939H142.744ZM140.92-753.1h57.825a4.991,4.991,0,0,0,4.984-4.985v-6.98a1.987,1.987,0,0,0-.585-1.41L179.8-789.823v-13.131a2,2,0,0,0-1.994-1.994H161.857a2,2,0,0,0-1.995,1.994v13.131L136.521-766.48a1.987,1.987,0,0,0-.585,1.41v6.98a4.991,4.991,0,0,0,4.984,4.985"
        transform="translate(261.271 2580.209)"
        fill="currentColor"
      />
      <g id="Groupe_1176" data-name="Groupe 1176" transform="translate(428.99 1833.963)">
        <path
          id="Tracé_824"
          data-name="Tracé 824"
          d="M0,0A2.983,2.983,0,0,0,0,4.227a2.985,2.985,0,0,0,4.227,0A2.985,2.985,0,0,0,4.227,0,3.085,3.085,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1177" data-name="Groupe 1177" transform="translate(431.104 1845.846)">
        <path
          id="Tracé_825"
          data-name="Tracé 825"
          d="M0,0A2.986,2.986,0,0,0-2.991,2.991,2.987,2.987,0,0,0,0,5.982,2.987,2.987,0,0,0,2.99,2.991,2.986,2.986,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1178" data-name="Groupe 1178" transform="translate(431.104 1858.607)">
        <path
          id="Tracé_826"
          data-name="Tracé 826"
          d="M0,0A2.986,2.986,0,0,0-2.991,2.99,2.987,2.987,0,0,0,0,5.982,2.986,2.986,0,0,0,2.99,2.99,2.986,2.986,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
      <path
        id="Tracé_947"
        data-name="Tracé 947"
        d="M0,0A3.007,3.007,0,0,0,.878-2.114,3,3,0,0,0,0-4.227a3.087,3.087,0,0,0-4.227,0A2.994,2.994,0,0,0-5.1-2.114,3,3,0,0,0-4.227,0,3.006,3.006,0,0,0-2.114.877,3,3,0,0,0,0,0"
        transform="translate(445.181 1838.19)"
        fill="currentColor"
      />
      <g id="Groupe_1179" data-name="Groupe 1179" transform="translate(446.625 1864.57)">
        <path
          id="Tracé_828"
          data-name="Tracé 828"
          d="M0,0A3,3,0,0,0,2.6-3.341a3,3,0,0,0-3.34-2.6A3,3,0,0,0-3.339-2.6,2.989,2.989,0,0,0-.368.02Z"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1180" data-name="Groupe 1180" transform="translate(445.031 1851.809)">
        <path
          id="Tracé_829"
          data-name="Tracé 829"
          d="M0,0A3,3,0,0,0,2.6-3.341a3,3,0,0,0-3.341-2.6A3,3,0,0,0-3.34-2.6,2.989,2.989,0,0,0-.369.019Z"
          fill="currentColor"
        />
      </g>
      <path
        id="Tracé_830"
        data-name="Tracé 830"
        d="M0,0A3,3,0,0,0-.878-2.114a3.086,3.086,0,0,0-4.226,0A2.994,2.994,0,0,0-5.982,0,3,3,0,0,0-5.1,2.114a3,3,0,0,0,2.113.876A3,3,0,0,0-.878,2.114,3.006,3.006,0,0,0,0,0"
        transform="translate(458.021 1836.076)"
        fill="currentColor"
      />
      <g id="Groupe_1182" data-name="Groupe 1182" transform="translate(464.313 1860.872)">
        <path
          id="Tracé_831"
          data-name="Tracé 831"
          d="M0,0A2.988,2.988,0,0,0-3.63-2.174,2.988,2.988,0,0,0-5.8,1.455,2.988,2.988,0,0,0-2.9,3.717l.728-.089A3,3,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1183" data-name="Groupe 1183" transform="translate(461.122 1848.108)">
        <path
          id="Tracé_832"
          data-name="Tracé 832"
          d="M0,0A2.989,2.989,0,0,0-3.629-2.173,2.991,2.991,0,0,0-5.8,1.458,2.992,2.992,0,0,0-2.9,3.72l.726-.09A2.988,2.988,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1184" data-name="Groupe 1184" transform="translate(417.026 1833.963)">
        <path
          id="Tracé_833"
          data-name="Tracé 833"
          d="M0,0A2.983,2.983,0,0,0,0,4.227a2.986,2.986,0,0,0,4.228,0A2.988,2.988,0,0,0,4.228,0,3.086,3.086,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1185" data-name="Groupe 1185" transform="translate(416.319 1858.627)">
        <path
          id="Tracé_834"
          data-name="Tracé 834"
          d="M0,0A3,3,0,0,0-3.34,2.6a3,3,0,0,0,2.6,3.341l.369.019A3,3,0,0,0,2.6,3.339,3,3,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1186" data-name="Groupe 1186" transform="translate(417.914 1845.865)">
        <path
          id="Tracé_835"
          data-name="Tracé 835"
          d="M0,0A3,3,0,0,0-3.34,2.6a3,3,0,0,0,2.6,3.341l.369.02A3,3,0,0,0,2.6,3.341,3,3,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1187" data-name="Groupe 1187" transform="translate(410.167 1836.076)">
        <path
          id="Tracé_836"
          data-name="Tracé 836"
          d="M0,0A3,3,0,0,0-.878-2.114a3.087,3.087,0,0,0-4.227,0A3.007,3.007,0,0,0-5.983,0a3.011,3.011,0,0,0,.878,2.114,3.005,3.005,0,0,0,2.114.876A3,3,0,0,0-.878,2.114,3.006,3.006,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1188" data-name="Groupe 1188" transform="translate(404.714 1845.937)">
        <path
          id="Tracé_837"
          data-name="Tracé 837"
          d="M0,0A2.988,2.988,0,0,0-3.628,2.174,3,3,0,0,0-1.456,5.8l.728.09a2.988,2.988,0,0,0,2.9-2.263A3,3,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1189" data-name="Groupe 1189" transform="translate(401.523 1858.697)">
        <path
          id="Tracé_838"
          data-name="Tracé 838"
          d="M0,0A2.988,2.988,0,0,0-3.629,2.174,3,3,0,0,0-1.455,5.8l.727.089a2.986,2.986,0,0,0,2.9-2.263A3,3,0,0,0,0,0"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default ShowerTop;
